/* src/App.css */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
  }

  .App {
    font-family: 'Source Code Pro', monospace;
    background: linear-gradient(to bottom,#000000, #0A3E07, #000000);
    color: #000000;
    background-size: cover;
    width: 100%;
  }
  
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    font-family: 'Source Code Pro', monospace;
    font-size:24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 20px;
    z-index: 1000;
  }

  .logo{
    flex: 1;
  }

  .logo img:hover{
    filter: invert(48%) sepia(89%) saturate(3362%) hue-rotate(305deg) brightness(95%) contrast(70%);
  }

  .logo img {
    max-width: 100px;
    height: auto;
    color: #2cc224;
    transition: filter 0.6 ease;
  }
  
  .hamburger {
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .nav {
    display: flex;
    flex-direction: row;
  }
  
  .nav.open {
    display: flex;
  }
  
  .nav a {
    color: #2cc224;
    text-decoration: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: color 0.3s ease;
    margin-right: 2rem;
  }
  
  .nav a:hover {
    color: #dd66cd;
  }
  
  .section {
    height: 100vh;
    width: 90%;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; 
    margin: 0 auto;
    font-family: 'Source Code Pro', monospace;
    font-weight: thin;
    color: greenyellow;
    font-size: 20px;
    margin-top: 60px;
  }

  .section h1 {
    font-size: 8.3rem;
    font-weight: 600;
    color: greenyellow;
  }

  .section h2 {
    margin-bottom: 1rem;
  }
  
  .content-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .text-container {
    flex: 1;
  }

  .image-container {
  margin-left: 40px;
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  width: 100%;
  max-width:250px;
  height: auto;
  border-radius: 20px;
}

.section p{
    margin-bottom: 1rem;
}

button{
    font-family: 'Source Code Pro', monospace;
    appearance: auto;
    text-align: center;
    padding-block: 1px;
    padding-inline: 6px;
    text-shadow: none;
    text-rendering: auto;
    display: inline-block;
    letter-spacing: normal;
}

.Button{
    padding: .5 rem;
    border-radius: 1rem;
    background-color: #09df1b7a;
    margin: .25rem;
    color: #f7fff8;
    border: none;
}

.typing-effect {
  overflow: hidden;
  white-space: nowrap;
  border-right: 3px solid #fff;
  animation: typing 2s steps(30, end), blink-caret 0.75s step-end infinite;
  font-size: 50px
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: white; }
}
  
